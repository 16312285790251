import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import Signup from './Signup';
import AddUser from './AddUser';
import ThankYou from './ThankYou';
import ProtectedRoute from './ProtectedRoute';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/posthogAPI' element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path='/addUser' element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/thank-you' element={<ThankYou />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;