const validation = (values) => {
  let errors = {};

  // API Key validation
  if (values.apiKey === "") {
    errors.apiKey = "API Key should not be empty";
  }

  // Region validation
  if (values.region === "") {
    errors.region = "Region should not be empty";
  }

  return errors;
};

export default validation;
