import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddUser.css';

function AddUser() {
    const navigate = useNavigate();
    const [selectedCrms, setSelectedCrms] = useState([]);
    const [newCrm, setNewCrm] = useState('');
    const generateRandomNumber = () => Math.floor(100000 + Math.random() * 900000);
    const [entries, setEntries] = useState([{ email: '', randomNumber: generateRandomNumber(), isValid: false, isUnique: true, error: '' }]);
    const [idCounter, setIdCounter] = useState(2);
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [loading, setLoading] = useState(false);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isEmailUnique = (email, index) => {
        return !entries.some((entry, i) => entry.email === email && i !== index);
    };

    const handleAddCrm = () => {
        if (newCrm && !selectedCrms.includes(newCrm)) {
            setSelectedCrms([...selectedCrms, newCrm]);
            setNewCrm('');
        }
    };

    const handleRemoveCrm = (crm) => {
        setSelectedCrms(selectedCrms.filter(item => item !== crm));
    };

    const handleEmailChange = (index, value) => {
        const isValid = emailPattern.test(value);
        const isUnique = isEmailUnique(value, index);

        const updatedEntries = entries.map((entry, i) =>
            i === index ? { ...entry, email: value, isValid, isUnique, error: '' } : entry // Reset error on change
        );
        setEntries(updatedEntries);
    };

    const addEmailEntry = () => {
        setEntries([
            ...entries,
            { email: '', randomNumber: generateRandomNumber(), isValid: false, isUnique: true, error: '' }
        ]);
        setIdCounter(idCounter + 1);
    };

    const removeEntry = (index) => {
        setEntries(entries.filter((_, i) => i !== index));
    };

    const copyToClipboard = (number, index) => {
        navigator.clipboard.writeText(number).then(() => {
            setCopiedIndex(index);
            setTimeout(() => setCopiedIndex(null), 2000);
        });
    };

    const allValidEntries = entries.length > 0 && entries.every(entry => entry.isValid && entry.isUnique);

    const handleSubmit = async () => {
        const token = localStorage.getItem('authToken');
        setLoading(true);

        try {
            const response = await fetch('https://tuneinusage-server.azurewebsites.net/add-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ selectedCrms, entries })
            });

            const data = await response.json();

            if (response.ok) {
                console.log('Form submitted successfully');
                setSelectedCrms([]);
                setEntries([{ email: '', randomNumber: generateRandomNumber(), isValid: false, isUnique: true, error: '' }]);
                navigate('/posthogAPI');
                setIdCounter(2);
            } else if (data.duplicates) {
                const updatedEntries = entries.map((entry) => {
                    const error = data.duplicates.includes(entry.email) ? 'Email already exists in the database' : '';
                    return { ...entry, error };
                });
                setEntries(updatedEntries);
            } else {
                console.error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <h2 className="header">Admin - Add User Emails</h2>

            {/* CRM input section */}
            <div className="crm-input-container">
                <label className="crm-label">Select the Software's you want to track</label>
                <div className="crm-input-wrapper">
                    <input
                        type="text"
                        value={newCrm}
                        onChange={(e) => setNewCrm(e.target.value)}
                        placeholder="Enter Saas name"
                        className="crm-input"
                    />
                    <button className="add-crm-btn" onClick={handleAddCrm} disabled={!newCrm}>
                        Add
                    </button>
                    <span className="add-crm-text">Simply type the name of the Software. e.g: HubSpot, Salesforce, Zoho</span>
                </div>
            </div>

            <div className="crm-list">
                {selectedCrms.map((crm, index) => (
                    <div key={index} className="crm-item">
                        {crm}
                        <button className="remove-crm-btn" onClick={() => handleRemoveCrm(crm)}>
                            ✕
                        </button>
                    </div>
                ))}
            </div>

            <div className="email-list">
                <label className="crm-label">Input the emails of the people in your organization that you want to track</label>
                {entries.map((entry, index) => (
                    <div key={index} className="entry">
                        <input
                            type="email"
                            className="email-input"
                            placeholder="Enter user email"
                            value={entry.email}
                            onChange={(e) => handleEmailChange(index, e.target.value)}
                        />
                        {entry.email && !entry.isValid && (
                            <small className="error-text">Invalid email format</small>
                        )}
                        {entry.email && !entry.isUnique && (
                            <small className="error-text">Email must be unique</small>
                        )}
                        {entry.error && (
                            <small className="error-text">{entry.error}</small>
                        )}
                        <div className="random-number-container">
                            <span className="random-number">{entry.randomNumber}</span>
                            <button
                                className="copy-btn"
                                onClick={() => copyToClipboard(entry.randomNumber, index)}
                            >
                                📋
                            </button>
                            {copiedIndex === index && (
                                <small className="copied-text">Copied!</small>
                            )}
                        </div>
                        <button
                            className="remove-btn"
                            onClick={() => removeEntry(index)}
                            disabled={index === 0} // Disable the first remove button
                        >
                            Remove
                        </button>
                    </div>
                ))}
            </div>

            <div className="button-container">
                <button
                    className="add-btn"
                    onClick={addEmailEntry}
                    disabled={!allValidEntries || loading}
                >
                    Add More
                </button>
                <button
                    className="submit-btn"
                    onClick={handleSubmit}
                    disabled={!allValidEntries || selectedCrms.length === 0 || loading} // Disable if no CRM items
                    title={selectedCrms.length === 0 ? 'Please add any CRM' : ''} // Tooltip message when CRM is empty
                >
                    {loading ? 'Submitting...' : 'Submit'}
                </button>
            </div>

            <div className="section">
                <label className="crm-label">Create a new Posthog instance to send the usage data in</label>
                <p className="section-subtitle">
                    We don’t at any point store your data; we simply pass it onto a Posthog instance where it's stored and processed.
                </p>
                <a href="https://posthog.com" target="_blank" rel="noreferrer" className="link">Configure Posthog</a>
            </div>
        </div>
    );
}

export default AddUser;