import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import validation from './LoginValidation';
import axios from 'axios';

function Login() {
    const [values, setValues] = useState({
        email: '',
        password: ''
    });

    const [error, setErrors] = useState({});
    const [loginFailed, setLoginFailed] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    const handleInput = (event) => {
        setValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validation(values);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setLoading(true); // Start loading
            try {
                const res = await axios.post('https://tuneinusage-server.azurewebsites.net/login', values);
                if (res.data.success) {
                    setLoginFailed(false);
                    localStorage.setItem('authToken', res.data.token);
                    navigate('/addUser');
                } else {
                    setLoginFailed(true);
                }
            } catch (err) {
                console.log(err);
                setLoginFailed(true);
            } finally {
                setLoading(false); // Stop loading after response
            }
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center vh-100' style={{ backgroundColor: '#729BD7' }}>
            <div className='bg-white p-3 rounded w-25'>
                <h3>Login-In</h3>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <label htmlFor='email' style={{ textAlign: 'left', display: 'block' }}><strong>Email</strong></label>
                        <input
                            type='email'
                            placeholder='Enter your Email'
                            className='form-control rounded-0'
                            name='email'
                            value={values.email}
                            onChange={handleInput}
                        />
                        {error.email && <span className='text-danger'>{error.email}</span>}
                    </div>

                    <div className='mb-3'>
                        <label htmlFor='password' style={{ textAlign: 'left', display: 'block' }}><strong>Password</strong></label>
                        <input
                            type='password'
                            placeholder='Enter your Password'
                            className='form-control rounded-0'
                            name='password'
                            value={values.password}
                            onChange={handleInput}
                        />
                        {error.password && <span className='text-danger'>{error.password}</span>}
                    </div>

                    {loginFailed && <p className='text-danger'>Invalid credentials</p>}

                    {/* Show loader if loading is true */}
                    <button type='submit' className='btn btn-success w-100 rounded-0 mb-3' disabled={loading}>
                        {loading ? (
                            <div className="spinner-border spinner-border-sm text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <strong>Log in</strong>
                        )}
                    </button>

                    <Link to="/signup" className='btn btn-default border w-100 bg-light rounded-0 text-decoration-none'>Create Account</Link>
                </form>
            </div>
        </div>
    );
}

export default Login;
