import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import validation from './HomeValidation';
import axios from 'axios';

function Home() {
    const [values, setValues] = useState({
        apiKey: '',
        region: ''
    });

    const [error, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const navigate = useNavigate();

    const handleInput = (event) => {
        setValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validation(values);
        setApiError('');
        setErrors(validationErrors);
    
        if (Object.keys(validationErrors).length === 0) {
            const { apiKey, region } = values;
            const token = localStorage.getItem('authToken');
    
            if (token) {
                try {
                    const updateResponse = await axios.post(
                        'https://tuneinusage-server.azurewebsites.net/update-posthog-api',
                        { apiKey, region },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
    
                    if (updateResponse.data.success) {
                        setApiError('');
                        navigate('/thank-you');
                    } else {
                        setApiError(updateResponse.data.message || 'Error updating PostHog API key');
                    }
                } catch (err) {
                    setApiError(err.response?.data?.message || 'Error updating PostHog API key. Please try again later.');
                }
            } else {
                navigate('/');
            }
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center vh-100' style={{ backgroundColor: '#729BD7' }}>
            <div className='bg-white p-3 rounded w-25'>
                <div className="bg-gray-100 min-h-screen flex items-center justify-center">
                    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
                        <h4 className="text-4xl font-bold text-green-600 text-center mb-8">How to Get Your PostHog API Key and Region</h4>

                        <ol className="list-decimal space-y-6 ml-5">
                            <li>
                                <h5 className="text-2xl font-semibold text-gray-800">Go to Your Settings</h5>
                                <p className="text-lg text-gray-600 mt-2">
                                    In the top-right corner of the settings, click on your <strong className="font-bold">"General"</strong> option.
                                </p>
                            </li>
                            <li>
                                <h5 className="text-2xl font-semibold text-gray-800">Navigate to "Project ID" and "Region"</h5>
                                <p className="text-lg text-gray-600 mt-2">
                                    1. In your profile settings, find the section labeled <strong className="font-bold">"Project API key"</strong>.
                                </p>
                                <p className="text-lg text-gray-600 mt-2">
                                    2. In your profile settings, locate the <strong className="font-bold">"Region"</strong> section and note down your region information.
                                </p>
                            </li>
                        </ol>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <label htmlFor='apiKey'><strong>API Key</strong></label>
                        <input
                            type='text'
                            placeholder='Copy and Paste the API Key Here'
                            className='form-control rounded-0'
                            name='apiKey'
                            value={values.apiKey}
                            onChange={handleInput}
                        />
                        {error.apiKey && <span className='text-danger'>{error.apiKey}</span>}
                    </div>

                    <div className='mb-3'>
                        <label htmlFor='region'><strong>Region</strong></label>
                        <input
                            type='text'
                            placeholder='Copy and Paste the Region'
                            className='form-control rounded-0'
                            name='region'
                            value={values.region}
                            onChange={handleInput}
                        />
                        {error.region && <span className='text-danger'>{error.region}</span>}
                        {apiError && <div className='text-danger text-center mt-3'>{apiError}</div>}
                    </div>

                    <button type='submit' className='btn btn-success w-100 rounded-0 mb-3'><strong>Submit</strong></button>
                </form>
            </div>
        </div>
    );
}

export default Home;
