import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('authToken');

    if (!token) {
        // No token found, redirect to login
        return <Navigate to="/" />;
    }

    // Decode the token to check its expiration time
    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    const isExpired = Date.now() >= tokenPayload.exp * 1000;

    if (isExpired) {
        // Token has expired, clear it from storage and redirect to login
        localStorage.removeItem('authToken');
        return <Navigate to="/" />;
    }

    return children; // Token is valid, allow access to the route
};

export default ProtectedRoute;
