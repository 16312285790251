import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import validation from './SignupValidation';
import axios from 'axios';

function Signup() {
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: ''
    });
    const navigate = useNavigate();
    const [error, setErrors] = useState({});

    const handleInput = (event) => {
        setValues(prev => ({ ...prev, [event.target.name]: [event.target.value] }))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validation(values);
        setErrors(validationErrors);

        // Check if there are no validation errors before making the API call
        if (Object.keys(validationErrors).length === 0) {
            await axios.post('https://tuneinusage-server.azurewebsites.net/signup', values)
                .then(res => {
                    navigate('/posthogAPI');
                })
                .catch(err => console.log(err));
        }
    };

    return (
        <div className='d-flex justify-content-center align-items-center vh-100' style={{ backgroundColor: '#729BD7' }}>
            <div className='bg-white p-3 rounded w-25'>
                <h3>Sign-Up</h3>
                <form action='' onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <label htmlFor='name' style={{ textAlign: 'left', display: 'block' }}><strong>Name</strong></label>
                        <input type='text' placeholder='Enter your Name' name='name' className='form-control rounded-0' onChange={handleInput} />
                        {error.name && <span className='text-danger'>{error.name}</span>}
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='email' style={{ textAlign: 'left', display: 'block' }}><strong>Email</strong></label>
                        <input type='email' placeholder='Enter your Email' name='email' className='form-control rounded-0' onChange={handleInput} />
                        {error.email && <span className='text-danger'>{error.email}</span>}
                    </div>

                    <div className='mb-3'>
                        <label htmlFor='password' style={{ textAlign: 'left', display: 'block' }}><strong>Password</strong></label>
                        <input type='password' placeholder='Enter your Password' name='password' className='form-control rounded-0' onChange={handleInput} />
                        {error.password && <span className='text-danger'>{error.password}</span>}
                    </div>

                    <button type='submit' className='btn btn-success w-100 rounded-0'><strong>Sign up</strong></button>
                    <p>You agree to our terms and policies</p>
                    <Link to="/" className='btn btn-default border w-100 bg-light rounded-0 text-decoration-none'>Login</Link>
                </form>
            </div>
        </div>
    )
}

export default Signup