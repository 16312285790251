function validation(values) {
    let error = {};

    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Updated pattern to allow special characters
    const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

    // Validate email
    const validateEmail = (email) => {
        return email_pattern.test(email);
    };

    // Validate password
    const validatePassword = (password) => {
        return password_pattern.test(password);
    };

    if (values.name === "") {
        error.name = 'Name should not be empty';
    }

    // Email validation
    if (values.email === "") {
        error.email = 'Email should not be empty';
    } else if (!validateEmail(values.email)) {
        error.email = 'Invalid Email';
    }

    // Password validation
    if (values.password === "") {
        error.password = 'Password should not be empty';
    } else if (!validatePassword(values.password)) {  
        error.password = 'Invalid Password';
    }

    return error;
}

export default validation;