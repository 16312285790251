import React from 'react';
import { Link } from 'react-router-dom';

const ThankYou = () => {
    return (
        <div className="d-flex justify-content-center align-items-center vh-100" style={{ backgroundColor: '#F3F4F6' }}>
            <div className="text-center p-5 rounded bg-white shadow" style={{ maxWidth: '500px' }}>
                <h2 className="text-success">Thank You!</h2>
                <p className="mt-3">Your submission was successful. We appreciate your response!</p>

                <p>If you have any questions, feel free to reach out.</p>

                <Link to="/addUser" className="btn btn-primary mt-3">Go to Homepage</Link>
            </div>
        </div>
    );
};

export default ThankYou;